import React from 'react'
import { SWComponentH1, SWComponentLink } from '../styles'

export default function SWComponent(props) {
    return (
        <>
            {
                <SWComponentLink to={`${props.input}/${props.name}`}>
                    <div>
                        <SWComponentH1>{props.name}</SWComponentH1>
                        <p>
                            <h4>{props.firstLineTag}</h4> {props.firstLine}
                        </p>
                        <p>
                            <h4>{props.secondLineTag}</h4> {props.secondLine}
                        </p>
                    </div>
                </SWComponentLink>
            }{' '}
        </>
    )
}
