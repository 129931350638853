import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import QanelasWoff from './assets/Fonts/QanelasSoft-Medium.woff'
import QanelasWoff2 from './assets/Fonts/QanelasSoft-Medium.woff2'
import RobotoWoff from './assets/Fonts/roboto-v29-latin-regular.woff'
import RobotoWoff2 from './assets/Fonts/roboto-v29-latin-regular.woff2'

export const FontStyles = createGlobalStyle`
@font-face {
  font-family: 'Qanelas Soft Medium';
  src: url(${QanelasWoff2}) format('woff2'),
       url(${QanelasWoff}) format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: url(${RobotoWoff2}) format('woff2'),
       url(${RobotoWoff}) format('woff');
}
`
/////////////////////////////////////////////////////////////////
export const Navbar = styled.ul`
    background: -webkit-linear-gradient(70deg, #5e5c5d, #0c0c0d);
    position: relative;
    min-height: 50px;
    margin-top: 0;
    padding-top: 13px;
    padding-bottom: 13px;
    text-align: left;
    margin: 0;
`

export const Brand = styled.li`
    display: inline-block;
    margin-right: 85%;
    font-size: 18px;
    white-space: nowrap;
    list-style-type: none;
    font-family: 'Qanelas Soft Medium';
    font-weight: 400;

    a {
        color: white;
        text-decoration: none;
        vertical-align: super;
        margin-left: 13px;

        &:hover {
            font-weight: 900;
        }
    }

    img {
        width: 36px;
        height: 36px;
        margin: auto;
        vertical-align: bottom;
    }
`

export const NavLi = styled.li`
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 15px;
    white-space: nowrap;
    text-decoration: none;
    list-style-type: none;
    color: white;
    font-family: 'Roboto', sans-serif;
    vertical-align: bottom;

    &:hover {
        font-weight: bold;
    }
`
/////////////////////////////////////////////////////////////////

export const Body = styled.div`
    text-align: center;
`

/////////////////////////////////////////////////////////////////

export const Sobre = styled.div`
    text-align: left;
    width: 50%;

    a {
        text-decoration: none;
    }
    p,
    li {
        font-family: 'Roboto', sans-serif;
        line-height: 28px;
        margin-left: 40px;
        color: #c6c5d1;
    }

    h3,
    h2,
    h1 {
        margin-top: 40px;
        margin-left: 40px;
        font-family: 'Qanelas Soft Medium';
        background: -webkit-linear-gradient(70deg, #fff, #5e5c5d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h1 {
        text-transform: uppercase;
    }
`

export const Footer = styled.div`
    font-family: 'Roboto', sans-serif;
    line-height: 28px;
    text-align: center;
    margin-top: 336px;
    margin-bottom: 0;
    padding: 2rem;
    background: #100113;
    display: block;

    p {
        margin: 0;
        color: #c6c5d1;
    }
`
/////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////

export const StyledHome = styled.header`
    display: flex;
    margin-bottom: 30px;

    div {
        margin: auto;
        width: 100%;
    }

    h1 {
        font-size: 50px;
        line-height: 65px;
        font-family: 'Qanelas Soft Medium';
        font-weight: bold;
        background: -webkit-linear-gradient(70deg, #2e67f8, #eb212e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h4 {
        line-height: 35px;
        font-family: 'Qanelas Soft Medium';
        font-weight: bold;
        color: #5e5c5d;
    }

    form {
        margin-top: 15px;
        margin-bottom: 35px;
    }

    h3 {
        margin-top: 35px;
        margin-bottom: 18px;
        font-family: 'Roboto', sans-serif;
        font-size: 25px;
        font-weight: bold;
        background: -webkit-linear-gradient(70deg, #fff, #5e5c5d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        font-family: 'Roboto', sans-serif;
        background: -webkit-linear-gradient(70deg, #fff, #b0abad);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }

    span {
        font-family: 'Roboto', sans-serif;
        background: -webkit-linear-gradient(70deg, #fff, #b0abad);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }

    img {
        margin: auto;
        width: 50%;
        margin-top: 1rem;
        filter: brightness(1.1) contrast(1.3);
        border-radius: 5px;
        margin-bottom: 2rem;
    }

    input {
        font-family: 'Roboto', sans-serif;
        background-image: linear-gradient(#02a117, #02a117),
            linear-gradient(#38d169, #38d169);
        border: 0 none;
        border-radius: 0;
        box-shadow: none;
        float: none;
        background-color: transparent;
        background-position: center bottom, center calc(100% - 1px);
        background-repeat: no-repeat;
        background-size: 0 2px, 100% 1px;
        padding: 0;
        transition: background 0s ease-out 0s;
        color: #525252;
        min-height: 35px;
        display: initial;
        width: 17%;
        outline: none;
        font-size: 15px;
        &:focus {
            background-size: 100% 2px, 100% 1px;
            outline: 0 none;
            transition-duration: 0.3s;
            color: #fff;
        }
    }

    select {
        display: block;
        margin: 20px auto 40px;
        padding: 5px;
        background-color: white;
        border: none;
        padding: 5px 1em 5px 5px;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        width: 10%;
    }
`

export const HomeDivImageBg = styled.div`
    display: flex;
    height: auto;
    pointer-events: none;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
`

export const HomeBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const HomeVideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #111;
`

export const HomeDivWrapperImageBg = styled.div`
    z-index: 3;
    display: flex;
    padding-top: 80px;
    padding: 25px 25px 0 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Grid = styled.div`
    margin: 4rem 5rem 0 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    a {
        font-family: 'Roboto', sans-serif;
        width: 40%;
        height: 400px;
        border-radius: 15px;
        margin: auto;
        background-color: #05459e;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    a:link {
        text-decoration: none;
    }

    a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
        background-color: #086fff;
    }

    a:active {
        text-decoration: none;
    }
`
/////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////

export const PaginatorAlign = styled.li`
    font-family: 'Qanelas Soft Medium';
    margin-top: 3px;
    padding-bottom: 3px;
`
export const PaginatorPrevNext = styled.a`
    font-family: 'Qanelas Soft Medium';
`
export const ButtonPrevNext = styled.button`
    font-family: 'Qanelas Soft Medium';
    margin-left: 5px;
    margin-right: 5px;
    border: 0px;
`
export const DivPaginator = styled.div`
    display: contents;
`
/////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////

export const Detalhes = styled.div`
    margin-right: 40px;
    h1 {
        text-align: left;
        margin-left: 40px;
        text-transform: uppercase;
        font-family: 'Qanelas Soft Medium';
        margin-top: 40px;
        margin-bottom: 40px;
        background: -webkit-linear-gradient(70deg, #fff, #b0abad);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }
    p {
        margin-top: 10px;
        margin-bottom: 10px;
        text-transform: capitalize;
        text-align: left;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        margin-left: 40px;
        color: #c6c5d1;
    }
`
/////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////

export const SWComponentH1 = styled.h1`
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 15px;
    margin-right: 15px;
    background: -webkit-linear-gradient(70deg, #fff, #b0abad) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
`
export const SWComponentLink = styled(Link)`
    display: inline-flex;
    align-self: center;
`

/////////////////////////////////////////////////////////////////
