import React from 'react'
import {
    DivPaginator,
    PaginatorAlign,
    PaginatorPrevNext,
    ButtonPrevNext,
} from '../styles'

function Paginator({ currentPage, changePage, input }) {
    if (input == 'people') {
        let nPages = 9
        const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

        const nextPage = () => {
            if (currentPage !== nPages) changePage(currentPage + 1)
        }
        const prevPage = () => {
            if (currentPage !== 1) changePage(currentPage - 1)
        }

        return (
            <nav>
                <ul className="pagination justify-content-center mt-5 mb-5 mr-3">
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={prevPage}>
                            Previous
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                    <DivPaginator>
                        {pageNumbers.map((pgNumber) => (
                            <PaginatorAlign
                                key={pgNumber}
                                className={`page-item ${
                                    currentPage == pgNumber ? 'active' : ''
                                } `}
                            >
                                <a
                                    onClick={() => changePage(pgNumber)}
                                    className="page-link"
                                >
                                    {pgNumber}
                                </a>
                            </PaginatorAlign>
                        ))}
                    </DivPaginator>
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={nextPage}>
                            Next
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                </ul>
            </nav>
        )
    }
    if (input == 'planets') {
        let nPages = 6
        const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

        const nextPage = () => {
            if (currentPage !== nPages) changePage(currentPage + 1)
        }
        const prevPage = () => {
            if (currentPage !== 1) changePage(currentPage - 1)
        }

        return (
            <nav>
                <ul className="pagination justify-content-center mt-5 mb-5 mr-3">
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={prevPage}>
                            Previous
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                    <DivPaginator>
                        {pageNumbers.map((pgNumber) => (
                            <PaginatorAlign
                                key={pgNumber}
                                className={`page-item ${
                                    currentPage == pgNumber ? 'active' : ''
                                } `}
                            >
                                <a
                                    onClick={() => changePage(pgNumber)}
                                    className="page-link"
                                >
                                    {pgNumber}
                                </a>
                            </PaginatorAlign>
                        ))}
                    </DivPaginator>
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={nextPage}>
                            Next
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                </ul>
            </nav>
        )
    }
    if (input == 'starships') {
        let nPages = 4
        const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

        const nextPage = () => {
            if (currentPage !== nPages) changePage(currentPage + 1)
        }
        const prevPage = () => {
            if (currentPage !== 1) changePage(currentPage - 1)
        }

        return (
            <nav>
                <ul className="pagination justify-content-center mt-5 mb-5 mr-3">
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={prevPage}>
                            Previous
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                    <DivPaginator>
                        {pageNumbers.map((pgNumber) => (
                            <PaginatorAlign
                                key={pgNumber}
                                className={`page-item ${
                                    currentPage == pgNumber ? 'active' : ''
                                } `}
                            >
                                <a
                                    onClick={() => changePage(pgNumber)}
                                    className="page-link"
                                >
                                    {pgNumber}
                                </a>
                            </PaginatorAlign>
                        ))}
                    </DivPaginator>
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={nextPage}>
                            Next
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                </ul>
            </nav>
        )
    }
    if (input == 'species') {
        let nPages = 4
        const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

        const nextPage = () => {
            if (currentPage !== nPages) changePage(currentPage + 1)
        }
        const prevPage = () => {
            if (currentPage !== 1) changePage(currentPage - 1)
        }

        return (
            <nav>
                <ul className="pagination justify-content-center mt-5 mb-5 mr-3">
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={prevPage}>
                            Previous
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                    <DivPaginator>
                        {pageNumbers.map((pgNumber) => (
                            <PaginatorAlign
                                key={pgNumber}
                                className={`page-item ${
                                    currentPage == pgNumber ? 'active' : ''
                                } `}
                            >
                                <a
                                    onClick={() => changePage(pgNumber)}
                                    className="page-link"
                                >
                                    {pgNumber}
                                </a>
                            </PaginatorAlign>
                        ))}
                    </DivPaginator>
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={nextPage}>
                            Next
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                </ul>
            </nav>
        )
    }
    if (input == 'vehicles') {
        let nPages = 4
        const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

        const nextPage = () => {
            if (currentPage !== nPages) changePage(currentPage + 1)
        }
        const prevPage = () => {
            if (currentPage !== 1) changePage(currentPage - 1)
        }

        return (
            <nav>
                <ul className="pagination justify-content-center mt-5 mb-5 mr-3">
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={prevPage}>
                            Previous
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                    <DivPaginator>
                        {pageNumbers.map((pgNumber) => (
                            <PaginatorAlign
                                key={pgNumber}
                                className={`page-item ${
                                    currentPage == pgNumber ? 'active' : ''
                                } `}
                            >
                                <a
                                    onClick={() => changePage(pgNumber)}
                                    className="page-link"
                                >
                                    {pgNumber}
                                </a>
                            </PaginatorAlign>
                        ))}
                    </DivPaginator>
                    <ButtonPrevNext className="page-item border-0 rounded">
                        <PaginatorPrevNext className="page-link" onClick={nextPage}>
                            Next
                        </PaginatorPrevNext>
                    </ButtonPrevNext>
                </ul>
            </nav>
        )
    }
}

export default Paginator
