import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSW = createApi({
    reducerPath: 'apiSW',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://swapi.dev/api/',
    }),
    endpoints: (builder) => ({
        getInfoByInputPaginated: builder.query({
            query: (paginatedAPI) => {
                const { searchInput, currentPage } = paginatedAPI
                return `${searchInput}/?page=${currentPage}`
            },
        }),
        getInfoByInputName: builder.query({
            query: (searchName) => {
                const { inputThing, nameThing } = searchName
                return `${inputThing}/?search=${nameThing}`
            },
        }),
    }),
})

export const { useGetInfoByInputPaginatedQuery, useGetInfoByInputNameQuery } = apiSW
