import React from 'react'
import { useGetInfoByInputNameQuery } from '../swAPI/sw-api-slice'
import { useParams } from 'react-router-dom'
import { Detalhes } from '../styles'

function IndividualPage() {
    const params = useParams()
    const nameThing = params.name
    const inputThing = params.input
    let dataAPI = []

    const { data, error, isLoading } = useGetInfoByInputNameQuery({
        inputThing,
        nameThing,
    })

    if (data) {
        dataAPI = data.results
    }

    return (
        <Detalhes>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
            ) : inputThing == 'people' ? (
                dataAPI.map((people) => (
                    <>
                        <h1>{people.name}</h1>
                        <p>
                            <b>Gender:</b> {people.gender}
                        </p>
                        <p>
                            <b>Heigth:</b> {people.height}cm
                        </p>
                        <p>
                            <b>Mass:</b> {people.mass}kg
                        </p>
                        <p>
                            <b>Birth Year:</b> {people.birth_year}
                        </p>
                        <p>
                            <b>Hair Color:</b> {people.hair_color}
                        </p>
                        <p>
                            <b>Skin Color:</b> {people.skin_color}
                        </p>
                    </>
                ))
            ) : inputThing == 'planets' ? (
                dataAPI.map((planets) => (
                    <>
                        <h1>{planets.name}</h1>
                        <p>
                            <b>climate:</b> {planets.climate}
                        </p>
                        <p>
                            <b>diameter:</b> {planets.diameter}km
                        </p>
                        <p>
                            <b>gravity:</b> {planets.gravity}
                        </p>
                        <p>
                            <b>population:</b> {planets.population}
                        </p>
                        <p>
                            <b>orbital period:</b> {planets.orbital_period} days
                        </p>
                        <p>
                            <b>rotation period:</b> {planets.rotation_period} days
                        </p>
                        <p>
                            <b>surface water:</b> {planets.surface_water}
                        </p>
                        <p>
                            <b>terrain:</b> {planets.terrain}
                        </p>
                    </>
                ))
            ) : inputThing == 'starships' ? (
                dataAPI.map((starships) => (
                    <>
                        <h1>{starships.name}</h1>
                        <p>
                            <b>MGLT:</b> {starships.MGLT}
                        </p>
                        <p>
                            <b>cargo capacity:</b> {starships.cargo_capacity}
                        </p>
                        <p>
                            <b>cost in credits:</b> {starships.cost_in_credits}
                        </p>
                        <p>
                            <b>hyperdrive rating:</b> {starships.hyperdrive_rating}
                        </p>
                        <p>
                            <b>manufacturer:</b> {starships.manufacturer}
                        </p>
                        <p>
                            <b>model:</b> {starships.model}
                        </p>
                        <p>
                            <b>passengers:</b> {starships.passengers}
                        </p>
                    </>
                ))
            ) : inputThing == 'species' ? (
                dataAPI.map((species) => (
                    <>
                        <h1>{species.name}</h1>
                        <p>
                            <b>classification:</b> {species.classification}
                        </p>
                        <p>
                            <b>designation:</b> {species.designation}
                        </p>
                        <p>
                            <b>average height:</b> {species.average_height}
                        </p>
                        <p>
                            <b>skin colors:</b> {species.skin_colors}
                        </p>
                        <p>
                            <b>hair colors:</b> {species.hair_colors}
                        </p>
                        <p>
                            <b>eye colors:</b> {species.eye_colors}
                        </p>
                        <p>
                            <b>average lifespan:</b> {species.average_lifespan}
                        </p>
                        <p>
                            <b>language:</b> {species.language}
                        </p>
                    </>
                ))
            ) : inputThing == 'vehicles' ? (
                dataAPI.map((vehicles) => (
                    <>
                        <h1>{vehicles.name}</h1>
                        <p>
                            <b>model:</b> {vehicles.model}
                        </p>
                        <p>
                            <b>cargo capacity:</b> {vehicles.cargo_capacity}
                        </p>
                        <p>
                            <b>cost in credits:</b> {vehicles.cost_in_credits}
                        </p>
                        <p>
                            <b>length:</b> {vehicles.length}
                        </p>
                        <p>
                            <b>manufacturer:</b> {vehicles.manufacturer}
                        </p>
                        <p>
                            <b>model:</b> {vehicles.model}
                        </p>
                        <p>
                            <b>passengers:</b> {vehicles.passengers}
                        </p>
                        <p>
                            <b>max atmosphering speed:</b>{' '}
                            {vehicles.max_atmosphering_speed}
                        </p>
                        <p>
                            <b>crew:</b> {vehicles.crew}
                        </p>
                        <p>
                            <b>consumables:</b> {vehicles.consumables}
                        </p>
                    </>
                ))
            ) : null}
        </Detalhes>
    )
}

export default IndividualPage
