import { Routes, Route } from 'react-router-dom'
import About from './pages/About'
import Homepage from './pages/Homepage'
import Nav from './components/Nav'
import { Body } from './styles'
import IndividualPage from './pages/IndividualPage'

function App() {
    return (
        <Body>
            <Nav />
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/about" element={<About />} />
                <Route path="/:input/:name" element={<IndividualPage />} />
            </Routes>
        </Body>
    )
}

export default App
