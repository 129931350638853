import React from 'react'
import {
    StyledHome,
    Grid,
    HomeDivImageBg,
    HomeDivWrapperImageBg,
    HomeBg,
    HomeVideoBg,
} from '../styles'
import swhome from '../assets/Images/star-wars-home.jpg'
import { useGetInfoByInputPaginatedQuery } from '../swAPI/sw-api-slice'
import { useState } from 'react'
import SWComponent from '../components/SWComponent'
import Paginator from '../components/Paginator'
import videoBg from '../assets/Videos/SpaceFlightLoop.mp4'

function Homepage() {
    /*     const [searchKey, setSearchKey] = useState('')*/
    const [searchInput, setSearchInput] = useState('people')
    const [currentPage, setCurrentPage] = useState(1)
    const { data, error, isLoading } = useGetInfoByInputPaginatedQuery({
        searchInput,
        currentPage,
    })

    let dataAPI = []

    if (data) {
        dataAPI = data.results
    }

    /*     const handleSearch = (e) => {
        setSearchKey(e.target.value.toLowerCase())
    } */

    console.log(dataAPI)

    return (
        <div>
            <StyledHome>
                <div>
                    <HomeDivImageBg>
                        <HomeBg>
                            <HomeVideoBg
                                autoPlay
                                muted
                                loop
                                src={videoBg}
                                type="video/mp4"
                            />
                        </HomeBg>
                        <HomeDivWrapperImageBg>
                            <h1>May the force be with you!</h1>
                            <img src={swhome} alt="" />
                        </HomeDivWrapperImageBg>
                    </HomeDivImageBg>

                    <h3>Start geeking!</h3>
                    <p>Search for rich contextual stuff from the universe</p>
                    {/*                     <form onChange={handleSearch}>
                        <input
                            className="search-bar"
                            type="text"
                            placeholder="Search for your favourite stuff!"
                            onChange={handleSearch}
                            value={searchKey}
                        />
                    </form>
 */}
                    <select
                        onChange={(e) => {
                            setSearchInput(e.target.value)
                            setCurrentPage(1)
                        }}
                    >
                        <option value={'people'} key={'people'}>
                            Characters
                        </option>
                        <option value={'planets'} key={'planets'}>
                            Planets
                        </option>
                        <option value={'starships'} key={'starships'}>
                            Starships
                        </option>
                        <option value={'species'} key={'species'}>
                            Species
                        </option>
                        <option value={'vehicles'} key={'vehicles'}>
                            Vehicles
                        </option>
                    </select>

                    <Paginator
                        currentPage={currentPage}
                        changePage={setCurrentPage}
                        input={searchInput}
                    ></Paginator>

                    <Grid>
                        {error ? (
                            <span>Oh no, there was an error!</span>
                        ) : isLoading ? (
                            <span>Getting your stuff...</span>
                        ) : searchInput == 'people' ? (
                            dataAPI.map((people) => (
                                <>
                                    <SWComponent
                                        key={people}
                                        input={'people'}
                                        name={people.name}
                                        firstLine={people.gender}
                                        secondLine={people.birth_year}
                                        firstLineTag={'Gender: '}
                                        secondLineTag={'Birth Year: '}
                                    />
                                </>
                            ))
                        ) : searchInput == 'planets' ? (
                            dataAPI.map((planets) => (
                                <>
                                    <SWComponent
                                        key={planets}
                                        input={'planets'}
                                        name={planets.name}
                                        firstLine={planets.climate}
                                        secondLine={planets.population}
                                        firstLineTag={'Climate: '}
                                        secondLineTag={'Population: '}
                                    />
                                </>
                            ))
                        ) : searchInput == 'starships' ? (
                            dataAPI.map((starships) => (
                                <>
                                    <SWComponent
                                        key={starships}
                                        input={'starships'}
                                        name={starships.name}
                                        firstLine={starships.model}
                                        secondLine={starships.starship_class}
                                        firstLineTag={'Model: '}
                                        secondLineTag={'Starship Class: '}
                                    />
                                </>
                            ))
                        ) : searchInput == 'species' ? (
                            dataAPI.map((species) => (
                                <>
                                    <SWComponent
                                        key={species}
                                        input={'species'}
                                        name={species.name}
                                        firstLine={species.language}
                                        secondLine={species.designation}
                                        firstLineTag={'Language: '}
                                        secondLineTag={'Designation: '}
                                    />
                                </>
                            ))
                        ) : searchInput == 'vehicles' ? (
                            dataAPI.map((vehicles) => (
                                <>
                                    <SWComponent
                                        key={vehicles}
                                        input={'vehicles'}
                                        name={vehicles.name}
                                        firstLine={vehicles.model}
                                        secondLine={vehicles.vehicle_class}
                                        firstLineTag={'Model: '}
                                        secondLineTag={'Vehicle Class: '}
                                    />
                                </>
                            ))
                        ) : null}
                    </Grid>
                    <Paginator
                        currentPage={currentPage}
                        changePage={setCurrentPage}
                        input={searchInput}
                    ></Paginator>
                </div>
            </StyledHome>
        </div>
    )
}

export default Homepage
