import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Brand, NavLi } from '../styles'
import icon from '../assets/Images/iconenav.png'

function Navigation() {
    const refreshPage = () => {
        window.location.reload()
    }
    return (
        <Navbar>
            <Brand onClick={refreshPage}>
                <img src={icon}></img>
                <Link to="/">SW Geeking</Link>
            </Brand>
            <Link to="/">
                <NavLi>HOME</NavLi>
            </Link>
            <Link to="/about">
                <NavLi>ABOUT</NavLi>
            </Link>
        </Navbar>
    )
}

export default Navigation
