import React from 'react'
import { Sobre, Footer } from '../styles'

function About() {
    return (
        <div>
            <Sobre>
                <h1>About</h1>
                <h2>MP2_TDW</h2>
                <p>
                    No âmbito da Unidade Curricular de Tecnologias e Desenvolvimento Web,
                    o docente João Abreu um desafio que consistia na criação de uma
                    aplicação de front-end em React para consumir e visualizar informação
                    proveniente de uma fonte pública de dados.<br></br>A API escolhida
                    para desenvolver este projeto foi a{' '}
                    <a href="https://swapi.dev/">The Star Wars API</a>, que é a primeira
                    fonte de dados quantificada e acessível por meio de programação para
                    todos os dados do universo canônico de Star Wars!{' '}
                </p>
                <h3>Autor</h3>
                <p>Henrique França | 98319</p>
            </Sobre>
            <Footer>
                <p>
                    Mestrado em Comunicação e Tecnologias Web 2022/23
                    <br></br>Universidade de Aveiro
                </p>
            </Footer>
        </div>
    )
}

export default About
